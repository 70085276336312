html,
body {
  height: 100%;
}

body {
  background: #212121; // TODO: Set in theme.
  font-family: sans-serif;
  font-size: 20px;
  margin: 0;
}

ul.reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.center-vertically {
  display: flex;
  align-items: center;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #00000099;
}

.mat-list-item-content {
  padding: 0 20px !important;
}

.mat-pseudo-checkbox {
  display: none !important;
}
